/* .demo_case_study_main_div{
    margin-top: 100px;
    display: flex;
    justify-content: space-evenly;
}

.demo_case_study_main_div > .demo_case_study_individual_card{
    padding: 15px;
    width: 45%;
    border-radius: 6px;
    background-color: white;
    box-shadow: 10px 10px 10px 2px rgba(199,199,199,0.77);
    -webkit-box-shadow: 10px 10px 10px 2px rgba(199,199,199,0.77);
    -moz-box-shadow: 10px 10px 10px 2px rgba(199,199,199,0.77);
}
.demo_case_study_main_div > .demo_case_study_individual_card > .demo_case_study_individual_card_image{
    padding: 15px;
    height: 200px;
    background-color: rgb(48, 48, 48);
    color: white;
}
.demo_case_study_main_div > .demo_case_study_individual_card > .demo_case_study_individual_card_title{
    padding: 15px;
    font-size: 25px;
}
.demo_case_study_main_div > .demo_case_study_individual_card > .demo_case_study_individual_card_desc{
    padding: 15px;
    font-size: 15px;
    color: #999;
}
.demo_case_study_main_div > .demo_case_study_individual_card > .demo_case_study_individual_card_download_button{
    text-align: center;
    margin-top: 10px;
}

.demo_case_study_modal_form{
    margin: 0 5% 0 5%;
}

.demo_case_study_modal_description{
    margin: 0 5% 0 5%;
}

.demo_case_study_modal_description > .demo_case_study_modal_description_title{
    font-size: 35px;
}

.demo_case_study_modal_description > .demo_case_study_modal_description_points > ul > li{
    font-size: 15px;
    color: #999;
}

@media only screen and (max-width: 1000px){
    .demo_case_study_main_div > .demo_case_study_individual_card{
        width: 80%;
    }
}

*/
.vvvs{
    background: url('../../../assets/images/shape/popup.svg') no-repeat top center;
    background-size: cover;
  } 

  .ppt_main_div{
    margin-top: 100px;
    display: flex;
    justify-content: space-evenly;
}

.ppt_main_div > .ppt_individual_card{
    padding: 15px;
    width: 45%;
    /* border: 1px solid #999; */
    border-radius: 6px;
    background-color: white;
    box-shadow: 10px 10px 10px 2px rgba(199,199,199,0.77);
    -webkit-box-shadow: 10px 10px 10px 2px rgba(199,199,199,0.77);
    -moz-box-shadow: 10px 10px 10px 2px rgba(199,199,199,0.77);
}
.ppt_main_div > .ppt_individual_card > .ppt_individual_card_image{
    padding: 15px;
    height: 200px;
    background-color: rgb(48, 48, 48);
    color: white;
}
.ppt_main_div > .ppt_individual_card > .ppt_individual_card_title{
    padding: 15px;
    font-size: 25px;
}
.ppt_main_div > .ppt_individual_card > .ppt_individual_card_desc{
    padding: 15px;
    font-size: 15px;
    color: #999;
}
.ppt_main_div > .ppt_individual_card > .ppt_individual_card_download_button{
    text-align: center;
    margin-top: 10px;
}

.ppt_modal_form{
    margin: 0 5% 0 5%;
}

.ppt_modal_description{
    margin: 0 5% 0 5%;
}

.ppt_modal_description > .ppt_modal_description_title{
    font-size: 35px;
}

.ppt_modal_description > .ppt_modal_description_points > ul > li{
    font-size: 15px;
    color: #999;
}

@media only screen and (max-width: 1000px){
    .ppt_main_div > .ppt_individual_card{
        width: 80%;
    }
}



.vvvvv{
    /* width:600px;
    height:120px; */
    /* background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0099ff" fill-opacity="1" d="M0,320L120,277.3C240,235,480,149,720,101.3C960,53,1200,43,1320,37.3L1440,32L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"></path></svg>')!important; */
    /* background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="500" height="100" viewBox="0 0 4442 720"><path d="M36,297.64c317.62,0,428,134.58,696,136.74S1160,364,1436,389s431.72-102.09,618-91.36,505.93,73.37,715,72.29,339,72,674,64.45,712.27,157.83,920,174l46,111.14H36Z" ></path></svg>'); */
  /* background-size:cover; */
  /* background-color:pink; */
  background: url('../../../assets/images/shape/popup.svg') no-repeat top center;
  background-size: cover;

}