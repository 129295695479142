*{
    margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  }

.knowledge_source_main_div{
    padding-top: 50px;
    /* background-color: #e8f7ff!important; */
    padding-bottom: 50px;
    margin-top: 100px;
}
.knowledge_source_main_div > .knowledge_source_title_div{
    font-size: 50px;
    text-align: center;
    margin-bottom: 40px;
    font-weight: bold;
    color: black;
}

.knowledge_source_main_div > div > .active{
    opacity: 1.1;
    background-color: #242424!important;
    color: white!important;
    font-weight: bold!important;
    border-radius: 50px!important;
}
.knowledge_source_main_div > div > label > input{
    visibility: hidden;
    height: 0;
    width: 0;
}
.knowledge_source_main_div > .btn-group{
    display: flex;
    justify-content: center;
    margin: 0 auto!important;
}
.knowledge_source_main_div > .btn-group{
    width: 650px!important;
    border: 1px solid rgb(212, 212, 212);
    padding: 5px;
    border-radius: 50px!important;
    background-color: white
;}
.knowledge_source_main_div > .btn-group > label{
    border-radius: 50px!important;
    margin: 0;
    font-size: 20px;
    background-color: white;
    border: none;
}
.knowledge_source_main_div > .btn-group > label > a > input{
    visibility: hidden;
    height: 0;
    width: 0;
}
.knowledge_source_main_div > .btn-group > label.active > a:hover{
    color: white;
}
.knowledge_source_main_div > .btn-group > label > a:hover{
    color: black;
}
.knowledge_source_main_div > .btn-group > label > a{
    text-decoration: none;
}

@media only screen and (max-width: 700px){
    .knowledge_source_main_div > .btn-group{
        width: 95%!important;
        border-radius: 20px!important;
        flex-wrap: wrap;
        /* margin: 10px!important */
    ;}

    .knowledge_source_main_div > .btn-group > label{
        width: 70%;
    }
}