/* *:focus {
    outline: none;
} */

.about_us_head{
    margin: 5%;
    border: 3px solid #f4c20d;
    text-align: center;
    font-size: 50px;
    margin-top: 150px;
}

.about_us_text_and_img_div{
    margin: 5%;
    padding: 3%;
    display: flex;
    background-color: #f4c20d;
}

.about_us_text_div{
    width: 40%;
    font-size: 35px;
    margin: auto;
}

.about_us_text_inner_div{
    font-size: 20px;
}

.about_us_why_u_need_us_div{
    margin: 0 5% 0 5%;
}

.about_us_why_u_need_us_title, .about_us_our_first_believers_title, .about_us_our_guiding_force_title{
    text-align: center;
    font-size: 30px;
    text-decoration: underline;
}

.about_us_why_u_need_us_paragraphs, .about_us_our_first_believers_ul{
    margin-top: 2%;
}

.about_us_why_u_need_us_paragraphs > p{
    font-size: 20px;
}

.about_us_our_first_believers_div{
    margin: 5%;
}

.about_us_our_first_believers_ul > ul > li{
    font-size: 20px;
}

.about_us_our_guiding_force_cards_div{
    display: flex;
    justify-content: space-evenly;
    margin-top: 2%;
}

.about_us_our_guiding_force_card{
    width: 300px;
    text-align: center;
    padding: 2%;
    -moz-box-shadow: 0 0 50px rgba(0,0,0,0.22);
    -webkit-box-shadow: 0 0 50px rgba(0,0,0,0.22);
    box-shadow: 0 0 50px rgba(0,0,0,0.22);
}

.about_us_our_guiding_force_card_image_div{
    /* position: relative; */
    display: block;
    margin: auto;
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid black;
}
.parthiban_image{
    /* position: relative; */
    margin-top: -35px;
}

.about_us_our_guiding_force_card_image_div img {
    width: 100%;
    height: auto;
}

.about_us_our_guiding_force_card_name{
    font-size: 30px;
    color: black;
}

.about_us_our_guiding_force_card_desc{
    padding: 2%;
    height: 250px;
    /* font-size: 20px; */
}

.about_us_our_guiding_force_card_position{
    font-size: 25px;
    text-decoration: underline;
}

.about_us_our_guiding_force_card_social{
    display: flex;
    justify-content: center;
    /* justify-content: space-around; */
}

.about_us_our_guiding_force_card_social > div{
    margin: 0px 10px 0 10px;
}


.about_us_our_guiding_force_card_social > div > a > button{
    background-color: #cccccc!important;
}
.about_us_our_guiding_force_card_social > div > a > button:hover{
    background-color: #008ed9!important;
}

@media only screen and (max-width: 1125px){
    .about_us_text_div{
        font-size: 30px;
        width: 100%;
    }
    
    .about_us_text_inner_div{
        font-size: 20px;
    }
    .about_us_head{
        margin-top: 7%;
    }
}
@media only screen and (max-width: 900px){
    .about_us_head{
        margin-top: 10%;
    }
    .about_us_our_guiding_force_cards_div{
        display: flex;
        flex-wrap: wrap;
    }
    .about_us_our_guiding_force_card{
        margin-bottom: 10px;
    }
}
@media only screen and (max-width: 1070px){
    .about_us_text_and_img_div{
        display: block;
        text-align: center;
    }

    .about_us_img_div > img{
        width: 90%;
    }
}
@media only screen and (max-width: 600px){
    .about_us_head{
        margin-top: 18%;
    }

    .about_us_text_div{
        font-size: 20px;
    }
    
    .about_us_text_inner_div{
        font-size: 13px;
    }
    .about_us_why_u_need_us_paragraphs > p{
        font-size: 15px;
        text-align: center;
    }
    
    .about_us_our_first_believers_ul > ul > li{
        font-size: 15px;
    }

    .about_us_why_u_need_us_title, .about_us_our_first_believers_title, .about_us_our_guiding_force_title{
        font-size: 20px;
    }
}