.pricing_table_main_div {
  width:100%;
  /* max-width:1000px; */
  margin:0 auto;
  padding-top: 50px;
}

table { 
  border-collapse:separate!important; 
  /* border-collapse:collapse;  */
  table-layout:fixed; 
  width:100%;
  border: 1px solid #f0f0f0!important;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 20px;
 }

table > thead > tr > th.pricing_table_plans_features{ 
  width: 300px;
  min-width: 300px;
  font-weight: 400;
  font-size: 15px;
 }

table > thead > tr.header_table > th:not(:first-child){ 
  width: 150px;
  min-width: 150px;
 }

.pricing_table_main_div > table > tbody > tr > td{ 
  font-size: 15px;
 }
/* th { background:#F5F5F5} */
th { background:#F5F5F5; }
td, th {
  height:53px;
  margin-left: 10px!important;
}
td,th { 
  border-top: none!important;
  border-bottom: none!important;
  padding:10px!important; 
  empty-cells:show; }
td:not(:last-child),th:not(:last-child) { 
  border-right:1px solid #f0f0f0; 
}
td,th {
  text-align:left;
}
td+td, th+th {
  text-align:center;
}
td.default {
  display:table-cell;
}
.bg-purple {
  /* border-top:3px solid #A32362; */
}
.bg-blue {
  /* border-top:3px solid #0097CF; */
}
.sep {
  background:#D4E0FF!important;
  font-weight:bold;
  vertical-align: middle!important;
  /* background-color: #D4E0FF; */
}

.additional_cost{
  font-weight: bold;
  font-size: 12px;
}
.additional_cost > span{
  font-size:12px; 
  color:#fbbc04;
}
.txt-l { font-size:28px; font-weight:bold; }
.txt-top { position:relative; top:-9px; left:-2px; }
.tick { font-size:18px; color:#2CA01C; }
.tickwithstar { font-size:18px; color:#fbbc04; }
.notick { font-size:18px; color:#ff0800; }
.hide {
  border:0;
  background:none;
}

@media (max-width: 640px) {

  /* .pricing_table_plans > tr > th{
    font-size: 9px;
    transform: rotate(-30deg);
    background: none;
    padding: 0;
  } */

  .txt-l { font-size:20px;font-weight: bold; }

  .pricing_table_main_div table{
    width: auto;
    /* table-layout:fixed; */
    /* overflow: hidden; */
    overflow-x: scroll;
  }
}