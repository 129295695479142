.blog_page_main_div{
    margin-top: 150px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.blog_page_main_div > .blog_page_blog_card{
    width: 45%;
    /* border: 1px solid #999; */
    /* padding: 10px; */
    border-radius: 6px;
    margin-top: 50px;
    transform: scale(1.00);
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s;
    box-shadow: 10px 10px 10px 2px rgba(199,199,199,0.77);
    -webkit-box-shadow: 10px 10px 10px 2px rgba(199,199,199,0.77);
    -moz-box-shadow: 10px 10px 10px 2px rgba(199,199,199,0.77);

}

.blog_page_main_div > .blog_page_blog_card:hover{
    cursor: pointer;
    transform: scale(1.05);
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s;
}

.blog_page_main_div > .blog_page_blog_card > a > .blog_page_blog_card_image > img{
    width: 100%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}
/* .blog_page_main_div > .blog_page_blog_card > a > .blog_page_blog_card_image{
    background-color: #008ed9;
    color: white;
    border: 1px solid #008ed9;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    font-size: 40px;
    padding: 20px;
} */

.blog_page_main_div > .blog_page_blog_card > a > .blog_page_blog_card_hashtags{
    display: flex;
    flex-wrap: wrap;
    margin-top: -20px;
    font-size: 12px;
}
.blog_page_main_div > .blog_page_blog_card > a > .blog_page_blog_card_hashtags > div{
    margin-left: 10px;
    border: 1px solid #008ed9;
    background-color: #d4f0ff;
    padding: 0 5px;
    border-radius: 4px;
    color: #008ed9;
    margin-top: 10px;
}

.blog_page_main_div > .blog_page_blog_card > a > .blog_page_blog_card_title{
    padding: 15px;
    font-size: 30px;
    color: #008ed9;
    height: 100px;
}

.blog_page_main_div > .blog_page_blog_card > a > .blog_page_blog_card_desc{
    padding: 15px;
    color: #999;
    min-height: 180px;
}

.blog_page_main_div > .blog_page_blog_card > a > .blog_page_blog_card_writtenby{
    display: flex;
    padding: 15px;
}

.blog_page_main_div > .blog_page_blog_card > a > .blog_page_blog_card_writtenby > .blog_page_blog_card_writtenby_image{
    /* height: 50px;
    width: 50px;
    background-color: wheat;
    border-radius: 50%;
    border: 1px solid #999; */
}
.blog_page_main_div > .blog_page_blog_card > a > .blog_page_blog_card_writtenby > .blog_page_blog_card_writtenby_image > img{
    height: 50px;
    width: 50px;
    background-color: wheat;
    border-radius: 50%;
    border: 1px solid #999;
}

.blog_page_main_div > .blog_page_blog_card > a > .blog_page_blog_card_writtenby > .blog_page_blog_card_writtenby_details{
    margin-left: 15px;
    margin-top: 5px;
}

.blog_page_main_div > .blog_page_blog_card > a > .blog_page_blog_card_writtenby > .blog_page_blog_card_writtenby_details > .blog_page_blog_card_writtenby_name{
    font-size: 15px;
    color: #008ed9;
}

.blog_page_main_div > .blog_page_blog_card > a > .blog_page_blog_card_writtenby > .blog_page_blog_card_writtenby_details > .blog_page_blog_card_writtenby_date{
    font-size: 12px;
    color: #999;
}

.individual_blog_main_div{
    /* margin-top: 100px; */
    margin: 10% 20% 10% 20%;
}

.individual_blog_main_div > .individual_blog_title_div{
    text-align: center;
    font-size: 40px;
}

.individual_blog_main_div > .individual_blog_date{
    font-size: 15px;
    color: #999;
    text-align: end;
}

.individual_blog_main_div > .individual_blog_image > img{
    /* text-align: center;
    background-color: #008ed9;
    color: white;
    height: 400px;
    width: 600px;
    font-size: 50px;
    margin: 0 auto;
    margin-top: 50px; */
    width: 100%;
}
/* .individual_blog_main_div > .individual_blog_image{
    text-align: center;
    background-color: #008ed9;
    color: white;
    height: 400px;
    width: 600px;
    font-size: 50px;
    margin: 0 auto;
    margin-top: 50px;
} */

.individual_blog_main_div > .individual_blog_paragraph{
    font-size: 20px;
    margin-top: 50px;
    /* color: #999; */
}

.individual_blog_main_div > .individual_blog_paragraph > .individual_blog_point{
    font-size: 25px;
    margin-bottom: 20px;
    font-weight: bold;
    /* color: #999; */
}

.individual_div_other_blog_single_blog_card_image > img{
    width: 100%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.individual_div_other_blog_single{
    /* border: 1px solid #999; */
    border-radius: 6px;
    box-shadow: 10px 10px 10px 2px rgba(199,199,199,0.77);
    -webkit-box-shadow: 10px 10px 10px 2px rgba(199,199,199,0.77);
    -moz-box-shadow: 10px 10px 10px 2px rgba(199,199,199,0.77);
    transform: scale(1.00);
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s;
}
.individual_div_other_blog_single:hover{
    /* border: 1px solid #999; */
    transform: scale(1.05);
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s;
}

.individual_div_other_blog_single_blog_card_title{
    font-size: 15px;
    padding: 5px;
    height: 50px;
    font-weight: bold;
}

.individual_div_other_blog_single_blog_card_desc{
    color: #999;
    font-size: 12px;
    padding: 5px;
    height: 80px;
    text-align: left;
}

.individual_blog_other_blogs_main_div{
    margin-top: 100px;
}
.individual_blog_other_blogs_main_div .swiper-button-prev{
    color: #008ed9!important;
}
.individual_blog_other_blogs_main_div .swiper-button-next{
    color: #008ed9!important;
}
.individual_blog_main_div > .other_blogs_title{
    font-size: 25px;
    margin-top: 30px;
    font-weight: bold;
    color: #008ed9;
    text-align: center;
}

@media only screen and (max-width: 970px){
    .individual_blog_main_div{
        margin-top: 100px!important;
    }
    .blog_page_main_div > .blog_page_blog_card{
        width: 90%;
    }
    .individual_blog_main_div{
        margin: 5%;
    }
}
@media only screen and (max-width: 730px){
    .individual_blog_other_blogs_display_Div{
        flex-wrap: wrap;
        /* text-align: center;
        justify-content: center; */
    }
    .individual_blog_other_blogs_display_Div > .individual_div_other_blog_single{
        width: 70%!important;
        margin-top: 20px;
    }
    .individual_div_other_blog_single_blog_card_title{
        height: auto;
    }
    
    .individual_div_other_blog_single_blog_card_desc{
        height: auto;
    }
}

@media only screen and (max-width: 600px){
    .blog_page_main_div > .blog_page_blog_card > a > .blog_page_blog_card_title{
        font-size: 20px;
        height: auto;
    }

    .individual_blog_main_div > .individual_blog_title_div{
        font-size: 30px;
    }
    .blog_page_main_div > .blog_page_blog_card > a > .blog_page_blog_card_desc{
        height: auto;
    }
}