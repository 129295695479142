.chatbot_main_div > .chatbot_whatsapp_div{
    position: fixed;
    bottom: 80px!important;
    right: 10px!important;
    z-index: 1000;
}

.chatbot_main_div > .chatbot_whatsapp_div > button > a{
    margin-left: 10px;
    margin-right: 10px;
}

.chatbot_main_div > .chatbot_whatsapp_div > button > a > .fa-whatsapp{
    margin-left: -3px;
}

.chatbot_main_div > .chatbot_requestdemo_div{
    position: fixed;
    left: 5%!important;
    bottom: 5%;
    z-index: 1000;
}
.chatbot_main_div > .chatbot_whatsapp_div > button{
    height: 60px;
    width: 60px;
    border: none;
    background-color: #25d366;
    border-radius: 50%;
    font-size: 20px;
}
.chatbot_main_div > .chatbot_whatsapp_div > button:hover{
    /* border: none; */
    background-color: #008ed0;
    /* border-radius: 15px;
    font-size: 20px; */
}

.chatbot_main_div > .chatbot_whatsapp_div > button > span > a{
    color: white!important;
    text-decoration: none;
    /* width: 40px; */
}

.calendly-badge-widget > .calendly-badge-content{
    background-color: #003cab!important;
}

@media only screen and (max-width: 560px){
    .chatbot_main_div > .chatbot_whatsapp_div > button > a > span{
        display: none;
    }

    .chatbot_main_div > .chatbot_whatsapp_div > button{
        border-radius: 50%;
        /* font-size: 20px; */
    }

    .chatbot_main_div > .chatbot_whatsapp_div > button > a{
        margin-left: 3px;
        margin-right: 3px;
    }

    .chatbot_main_div > .chatbot_whatsapp_div > button > a > .fa-whatsapp{
        margin-left: 0px;
    }

    .chatbot_main_div > .chatbot_whatsapp_div{
        bottom: 10px!important;
        right: 10px!important;
    }

}