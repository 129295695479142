.enterprise_plan .btn-group{
    /* display: flex;
    justify-content: center;
    margin: 0 auto!important; */
}
.enterprise_plan .btn-group{
    width: 400px;
    border: 1px solid rgb(212, 212, 212);
    /* padding: 5px; */
    border-radius: 50px!important;
    background-color: white
;}
.enterprise_plan .btn-group > label{
    border-radius: 50px!important;
    margin: 0;
    font-size: 20px;
    background-color: white;
    color: black;
    border: none;
}

.enterprise_plan .monthly_yearly{
    display: flex;
    justify-content: center;
    margin: 0 auto!important;
}

.enterprise_plan .monthly_yearly{
    width: 400px;
    border: 1px solid rgb(212, 212, 212);
    padding: 5px;
    border-radius: 50px!important;
    background-color: white
;}
.enterprise_plan .monthly_yearly > label{
    border-radius: 50px!important;
    margin: 0;
    font-size: 20px;
    background-color: white;
    color: black;
    border: none;
}

.enterprise_plan .btn-primary{
    padding: 2px 4px!important;
}

.enterprise_plan .btn-primary.active{
    background-color: #3665DE!important;
}

.btn, label::before{
    box-shadow: none !important
}

.enterprise_plan .list_class{
    border: 1px solid #AEAEAE;
    border-radius: 40px!important;
    padding: 25px;
}

.enterprise_plan .list_class li{
    border: none!important;
    padding: 2px;
}

.enterprise_plan .title{
    font-size: 32px;
    font-weight: 700;
    color: black;
}

.enterprise_plan .plan_includes ul{
    padding-top: 20px;
}

.enterprise_plan .plan_includes ul li{
    font-size: 15px;
}

.enterprise_plan .choose_your_title{
    font-size: 25px;
    color: black;
}

.enterprise_plan .responsive_titlee{
    /* "marginRight":"10px","width":"170px","textAlign":"end" */
    margin-right: 10px;
    width: 170px;
    text-align: end;
}
@media only screen and (max-width: 990px){
    .enterprise_plan .responsive_titlee{
        margin: 0 auto;
        width: auto;
        text-align: center;
    }

    .enterprise_plan .btn-group{
        width: 100%;
    ;}

    .enterprise_plan h3{
        font-size: 20px
    ;}

    .enterprise_plan .choose_your_title{
        font-size: 16px;
        color: black;
    }

    .enterprise_plan .choose_your_title > span{
        display: block;
    }

    .enterprise_plan .title{
        font-size: 20px;
    }
}
